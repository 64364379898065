import { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { createPortal } from "react-dom";
import { BiChevronLeftCircle } from "react-icons/bi";
import { Card } from "./Card";
import { CloseButton } from "./CloseButton";

interface ModalProps {
  close: () => void;
  heading?: ReactNode;
  children?: ReactNode;
  fullScreen?: boolean;
}
export const Modal = ({ close, children, heading, fullScreen = false }: ModalProps) => {
  const { t } = useModifiedTranslation();
  const [visible, setVisible] = useState(false);

  const closeModal = () => {
    setVisible(false);
    setTimeout(() => {
      close();
    }, 200);
  };

  useEffect(() => {
    setVisible(true);
    document.body.style.overflowY = "hidden";
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        close();
      }
    });
    return () => {
      document.body.style.overflowY = "auto";
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          close();
        }
      });
    };
  }, [close]);

  if (fullScreen) {
    return createPortal(
      <div
        className={classNames(
          "w-full h-full fixed opacity-0 top-0 left-0 z-[500] bg-white transition-opacity overflow-y-auto",
          { "opacity-100": visible }
        )}>
        <div className="sticky top-0 left-0 w-full bg-white z-[501]">
          <div className="container flex justify-between px-2 pt-4 pb-2 m-auto mb-2 w-full md:w-5/6 lg:w-4/6 xl:w-3/6 h-full border-b">
            <div
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => closeModal()}
              className="flex gap-2 items-center p-1 text-xl font-bold hover:bg-gray-100 hover:rounded-lg cursor-pointer">
              <BiChevronLeftCircle tabIndex={1} name="test" /> {t("modal.back")}
            </div>
            <CloseButton onClick={() => closeModal()} />
          </div>
        </div>
        <div className="container m-auto w-full md:w-5/6 lg:w-4/6 xl:w-3/6 h-full">{children}</div>
      </div>,
      document.getElementById("root") ?? document.body
    );
  }

  return createPortal(
    <div
      role="dialog"
      aria-hidden="true"
      tabIndex={-1}
      aria-modal="true"
      onClick={(e) => {
        e.preventDefault();
        closeModal();
      }}
      className={classNames(
        "z-[400] bg-gray-300/50 opacity-0 fixed top-0 left-0 w-full h-full transition-opacity",
        { "opacity-100": visible }
      )}>
      <div
        role="document"
        onClick={(e) => e.stopPropagation()}
        className="fixed top-1/2 left-1/2 w-4/5 md:w-3/5 lg:w-2/5 xl:w-1/3 -translate-x-1/2 -translate-y-1/2 z-[500]">
        <Card options={{ heading: { size: "sm" } }}>
          <div className="flex justify-end px-2 pt-4 pb-2 mb-2 w-full border-b">
            <div className="z-[501]">
              <CloseButton onClick={() => closeModal()} />
            </div>
            {heading && (
              <div className="flex absolute top-0 items-center px-2 pt-4 pb-2 mb-2 w-full">
                <div className="flex justify-center items-center w-full h-full text-xl font-bold">
                  {heading}
                </div>
              </div>
            )}
          </div>

          {children}
        </Card>
      </div>
    </div>,
    document.getElementById("root") ?? document.body
  );
};
