import classNames from "classnames";
import { BsCheck } from "react-icons/bs";

interface CheckBoxProps {
  isChecked?: boolean;
  onClick?: () => void;
  groupVisible?: boolean;
  disabled?: boolean;
  stopPropagation?: boolean;
}
export const CheckBox = ({
  isChecked,
  onClick,
  groupVisible,
  disabled,
  stopPropagation,
}: CheckBoxProps) => {
  return (
    <div
      className={classNames("flex justify-start items-center", {
        "group-hover:visible": groupVisible,
        visible: isChecked && groupVisible,
        invisible: !isChecked && groupVisible,
      })}>
      <div
        onClick={(e) => {
          onClick && !disabled && onClick();
          stopPropagation && e.stopPropagation();
        }}
        className={classNames(
          "border rounded-[0.25rem] h-4 w-4 flex justify-center items-center",
          disabled ? "border-grey/50" : "border-buttonColor",
          {
            "bg-buttonColor": isChecked,
            "cursor-pointer": onClick && !disabled,
            "cursor-not-allowed": disabled,
          }
        )}>
        {isChecked && <BsCheck className="text-white" />}
      </div>
    </div>
  );
};
